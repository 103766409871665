<template>
  <section class="head">
    <div class="bg"></div>
    <template v-if="resultPage">
      <div class="top">
          <a v-show="userCarrier" class="left" @click="onClickBarcode">
            <font-awesome-icon icon="fa-solid fa-barcode" size="xl" />
          </a>
        <a v-if="isLoggedIn" class="right">
          <LoginButton />
        </a>
      </div>
      <span> {{ shopName }} </span>
    </template>
    <template v-else>
      <div class="left">
        <a v-show="userCarrier" @click="onClickBarcode">
          <font-awesome-icon icon="fa-solid fa-barcode" size="xl" />
        </a>
      </div>
      <div class="center">
        <span> {{ shopName }} </span>
        <span class="price"> {{ formatCurrency(amount, currencySettings) }}</span>
      </div>
      <div class="right">
        <LoginButton />
      </div>
    </template>
    <!-- popBarcode放在這會有CSS問題-->
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";
import LoginButton from "@/components/LoginButton.vue";
import { formatCurrency } from "@/utils/utils";

export default {
  name: "OrderHeader",
  props: {
    showGoBack: {
      type: Boolean,
      default: false,
    },
    resultPage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoginButton
  },
  data() {
    return {
      formatCurrency,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
      order: (state) => state.orderInfo,
      // amount: (state) => state.amount,
    }),
    ...mapGetters({
      shopName: "getShopName",
      amount: "getAmount",
      thirdPartyMember: 'getThirdPartyMemberShip'
    }),
    history() {
      return window.history.length;
    },
    userCarrier() {
      if (this.user?.user?.cardNo) {
        return this.user.user.cardNo.trim();
      } else if (this.thirdPartyMember?.carrier) {
        return this.thirdPartyMember.carrier.trim();
      }
      return null;
    },
    currencySettings() {
      return this.order?.orderInfo?.currencySettings || null;
    },
  },
  methods: {
    goBack() {
      if (window.history.length > 3) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: "Payment",
          params: { token: this.$router.params.token },
        });
      }
    },
    onClickBarcode() {
      this.$store.commit('updateShowUserCarrier', true)
    }
  },
};
</script>
